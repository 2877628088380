<template>
	<b-button
		@click="exportFile"
		style="margin-right: 5px;"
		pill
		variant="info"
		:disabled="disableButton"
        title="Descargar a Excel"
        v-b-tooltip.hover.v-primary
	>
		Descargar a Excel</b-button
	>
</template>
<script>
	import XLSX from 'xlsx';
	import functionsReports from '../../functions/functionsReports';
	export default {
		name: 'exportExcel',
		props: {
			fileName: {
				type: String,
				default: function() {
					return 'Reporte';
				},
			},
			dataPostReport: {
				type: Object,
				required: true,
			},
			disableButton: {
				type: Boolean,
				default: function() {
					return false;
				},
			},
		},
		data() {
			return {
				dataToExport: [],
			};
		},
		methods: {
			//Metodo encargado de realizar el export del archivo Excel.
			exportFile: function() {
				functionsReports
					.postGenerateReport(this.dataPostReport)
					.then((response) => {
						let dataToExport = [];
						for (let index = 0; index < response.length; index++) {
							dataToExport[index] = {
								'Nombre Comercial': response[index].name,
								'Ejecutivo de Ventas':
									response[index].sellerName,
								'Fecha de Envío': response[index].sendDate,
								Estatus: response[index].status.name,
								'E-Mail': response[index].email,
								'Enviado por': response[index].user.name,
							};
						}
						this.saveFile(dataToExport);
					})
					.catch((error) => {
						console.log(error);
					});
			},
			saveFile(dataToExport) {
				let data = XLSX.utils.json_to_sheet(dataToExport);
				const workbook = XLSX.utils.book_new();
				const filename = this.fileName;
				XLSX.utils.book_append_sheet(workbook, data, filename);
				XLSX.writeFile(workbook, `${filename}.xlsx`);
			},
		},
	};
</script>
