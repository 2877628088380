import axiosInfoFeed from '../axiosConfig';
const functionsReports = {
	async getSellersAutocomplete(textInput) {
		const response = await axiosInfoFeed.get('api/v1/sellers/' + textInput);
		this.response = response.data.data;
		return this.response;
	},
	async postGenerateReport(dataForPost, dataPagination = null, filter) {
		let search = filter !== null ? '&like_by=' + encodeURI(filter) : '';
		if (dataPagination !== null) {
			const response = await axiosInfoFeed.post(
				'api/v1/report?per_page=' +
					dataPagination.recordsPerPage +
					'&page=' +
					dataPagination.page +
					search,
				dataForPost
			);
			this.response = response.data.data;
		} else {
			const response = await axiosInfoFeed.post(
				'api/v1/report',
				dataForPost
			);
			this.response = response.data.data;
		}
		return this.response;
	},
	async getChartHome() {
		const response = await axiosInfoFeed.get('api/v1/report/charthome');
		this.response = response.data.data;
		return this.response;
	},
};
export default functionsReports;
