<template>
	<div>
		<CRow>
			<CCol sm="6" style="margin-top: 20px;">
				<p class="h6 justify-text">
					Ejecutivo de Ventas
				</p>
				<autocomplete
					:search="search"
					placeholder="Ingresa el Ejecutivo de Ventas"
					aria-label="Ejecutivo de Ventas"
					:get-result-value="getResultValue"
					@submit="onSubmit"
				></autocomplete
				><br />
				<CAlert color="danger" v-if="this.errorAutocomplete !== ''">{{
					this.errorAutocomplete
				}}</CAlert>
			</CCol>
		</CRow>
		<CRow v-if="showLeaderZoneSwitch">
			<CCol sm="6" class="content-right">
				<p class="h6">
					Generar reporte de su Zona
					<CSwitch
						class="mx-1"
						color="success"
						:checked.sync="leaderZoneChecked"
						variant="3d"
						labelOn="Si"
						labelOff="No"
						@update:checked="zoneChecked"
						ref="leaderZone"
					/>
				</p>
			</CCol>
		</CRow>
		<br />
		<CRow class="content-center">
			<CCol sm="12">
				<b-button
					@click="goToReport"
					title="Generar Reporte"
					id="generate"
					pill
					variant="primary"
					class="mr-1 text-white"
					:disabled="disableButton"
					v-b-tooltip.hover.v-primary
				>
					Generar Reporte
				</b-button>
			</CCol>
		</CRow>
	</div>
</template>
<script>
	import functionsReports from '../../functions/functionsReports';
	export default {
		name: 'sellerAutocomplete',
		created() {},
		data() {
			return {
				errorAutocomplete: '',
				showLeaderZoneSwitch: false,
				leaderZoneChecked: false,
				disableButton: true,
				isLeaderZone: 1,
			};
		},
		methods: {
			search(input) {
				this.showLeaderZoneSwitch = false;
				this.disableButton = true;
				return new Promise((resolve) => {
					this.errorAutocomplete = '';
					if (input.length < process.env.VUE_APP_CANT_LETTERS_INPUT) {
						return resolve([]);
					}
					functionsReports
						.getSellersAutocomplete(encodeURI(input))
						.then((response) => {
							this.errorAutocomplete = '';
							if (response.length === 0) {
								this.errorAutocomplete =
									'OOPS!!! No se encontró lo que buscabas.';
							}
							resolve(response);
						})
						.catch((error) => {
							this.errorAutocomplete =
								'OOPS!!! Ocurió un error con la búsqueda.';
							console.log(error);
							return resolve([]);
						});
				});
			},
			getResultValue(result) {
				return result.nombre_completo;
			},
			onSubmit(result) {
				this.disableButton = false;
				if (result.jefe_grupo === this.isLeaderZone) {
					this.showLeaderZoneSwitch = true;
				}
				this.$emit('submit', result);
			},
			zoneChecked(value) {
				this.$emit('zoneChecked', value);
			},
			goToReport() {
				this.$emit('generate');
			},
		},
	};
</script>
