<template>
	<div>
		<!-- titulo -->
		<CRow>
			<CCol sm="12" class="content-center">
				<h4 v-if="dataPostReport.checkZoneSales">
					Zona de ventas de {{ sellerName }}
				</h4>
				<h4 v-else>Clientes de {{ sellerName }}</h4>
			</CCol>
		</CRow>
		<br />
		<!-- informacion -->
		<CRow>
			<CCol sm="12">
				<p class="h6 justify-text">
					Para generar nuevamente el reporte con otro vendedor haz
					clic en el bot&oacute;n <b>Regresar</b>.
				</p>
				<p class="h6 justify-text">
					Puedes descargar el reporte haciendo clic en el bot&oacute;n
					<b>Descargar a Excel</b>.
				</p>
				<p class="h6 justify-text">
					Puedes usar el campo con el icono
					<i class="fas fa-search"></i> para filtrar los resultados.
				</p>
			</CCol>
		</CRow>
		<!-- buscador de la tabla -->
		<search-tables @search="doSearch" />
		<!-- tabla -->
		<CRow>
			<CCol sm="12">
				<p style="margin-bottom: 30px;">
					<span class="float-right"
						>Total:
						<CBadge shape="pill" color="info">
							{{ this.dataForPager.totalRecords }}</CBadge
						>
					</span>
				</p>
				<b-table
					class="text-left"
					stacked="lg"
					striped
					outlined
					hover
					bordered
					:busy.sync="isBusy"
					:items="dataTable"
					:fields="fieldsTable"
					ref="tableReport"
					show-empty
				>
					<template #empty="">
						<p class="h6 justify-text">
							No existe data para <b>{{ sellerName }}</b
							>, intenta consultar otro ejecutivo de ventas.
						</p>
					</template>
				</b-table>
			</CCol>
		</CRow>
		<!-- paginado -->
		<pager
			v-if="this.dataForPager.totalRecords > 0"
			:data-pager="this.dataForPager"
			@changePage="changePage"
		/>
		<br />
		<!-- boton regresar y exportar a excel-->
		<CRow>
			<CCol sm="12">
				<CRow class="content-right">
					<CCol sm="12">
						<export-excel
							:data-post-report="dataPostReport"
							:disable-button="
								dataTable.length == 0 ? true : false
							"
							:file-name="nameForExport"
						/>
						<b-button
							@click="goBack"
							title="Regresar"
							id="goBack"
							pill
							variant="warning"
							class="mr-1 text-white"
							v-b-tooltip.hover.v-warning
						>
							Regresar
						</b-button>
					</CCol>
				</CRow>
			</CCol>
		</CRow>
	</div>
</template>

<script>
	import functionsReports from '../../functions/functionsReports';
	import Pager from '../general/pager.vue';
	import ExportExcel from './exportExcel.vue';
	import SearchTables from '../../components/general/searchTables.vue';
	export default {
		components: { Pager, ExportExcel, SearchTables },
		name: 'tableReport',
		props: {
			fieldsTable: {
				type: Array,
				default: function() {
					return [
						{
							key: 'comercialName',
							label: 'Nombre Comercial',
						},
						{
							key: 'seller',
							label: 'Ejecutivo de Ventas',
						},
						{
							key: 'dateSend',
							label: 'Fecha de Envío',
						},
						{
							key: 'status',
							label: 'Estatus',
						},
						{
							key: 'email',
							label: 'E-Mail',
						},
						{
							key: 'sendedFrom',
							label: 'Enviado por',
						},
					];
				},
			},
			dataPostReport: {
				type: Object,
				required: true,
			},
			sellerName: {
				type: String,
				default: function() {
					return '';
				},
			},
			nameForExport: {
				type: String,
				default: function() {
					return 'Reporte';
				},
			},
		},
		data() {
			return {
				dataTable: [],
				isBusy: false,
				dataForPager: {
					recordsPerPage: process.env.VUE_APP_RECORDS_PER_PAGE,
					currentPage: 1,
					totalRecords: 0,
					page: 1,
				},
				page: null,
				searchText: null,
			};
		},
		created() {
			this.tableProvider();
		},
		methods: {
			async tableProvider() {
				let data;
				let promise = functionsReports.postGenerateReport(
					this.dataPostReport,
					this.dataForPager,
					this.searchText
				);
				this.isBusy = true;
				try {
					const response = await promise;
					const items = [];
					this.isBusy = false;
					if (response.total !== undefined && response.total > 0) {
						this.dataForPager.totalRecords = response.total;
						this.dataForPager.recordsPerPage = response.per_page;
						this.dataForPager.currentPage = response.current_page;
						for (
							let index = 0;
							index < response.data.length;
							index++
						) {
							items[index] = {
								comercialName: response.data[index].name,
								seller: response.data[index].sellerName,
								dateSend: response.data[index].sendDate,
								status: response.data[index].status.name,
								email: response.data[index].email,
								sendedFrom: response.data[index].user.name,
							};
						}
						this.dataTable = items;
					}
					return items;
				} catch (error) {
					console.log(error);
					return [];
				}
			},
			goBack() {
				this.dataTable = [];
				this.$emit('goBack');
			},
			changePage(page) {
				this.page = page;
				this.dataForPager.page = page;
				this.tableProvider();
				this.$refs.tableReport.refresh();
			},
			doSearch(value) {
				this.searchText = value;
				this.tableProvider();
				this.$refs.tableReport.refresh();
			},
		},
	};
</script>

<style></style>
